<template>
  <div class="container">
    <div class="query-title">
      <div class="demo-input-suffix">
        <p class="titname">消费记录列表</p>
        <!-- <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button> -->
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="申请时间" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
        <el-table-column prop="withdrawalAmount" label="提现金额(元)" align="center">
          <template slot-scope="scope">
            <p>{{scope.row.withdrawalAmount}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="110" align="center">
          <template slot-scope="scope">
            <el-button class="tabBnt" v-if="scope.row.state==0" type="text" @click="pass(scope.row, scope.$index)">通过</el-button>
            <el-button class="tabBnt" v-if="scope.row.state==0" type="text" @click="refuse(scope.row, scope.$index)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>
<script>
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {gwithdrawallist,gwithdrawalupdate} from "@/api/api";
  export default {
    name: "withdrawal",
    components: {
      pagintion,
    },
    props: {},
    data() {
      return {
        specimenname: "",
        tabledata: [],
        tableHeight: window.innerHeight - 235,
        dialogData: [],
        pageNum: 1,
        pageSize: 20,

        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 235;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },
      refuse(row,index){
        this.$confirm("此操作将拒绝该申请,拒绝后无法撤销, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = row;
            params.state = 2
            gwithdrawalupdate(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已通过",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});
      },
      pass(row,index){
        this.$confirm("此操作将通过该申请,通过后无法撤销, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = row;
            params.state = 1
            gwithdrawalupdate(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已通过",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});
      },
      edit(row, index) {
        this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑";
      },
      deletes(row) {

        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]
            eworkgroupdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },

      Datalist() {
        let params = {
          phone:'',
          name:'',
          state:'',
          size:this.pageSize,
          current:this.pageNum,
        }
        gwithdrawallist(params).then((res) => {

          this.tabledata = res.data.list;
          this.total = Number(res.data.total);

        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
